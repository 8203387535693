import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'
import AngleLeft from '@components/Icons/AngleLeft.jsx'
import CloseX from '@components/Icons/Close-X.jsx'
import * as nav from '@data/navdata'

const SubnavNursery = ({
    activeSubnav,
    handleToggleSubnav,
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
    handleCloseMenu,
}) => {
    return (
        <nav
            className={`subnav-wrap w-full h-full bg-[#FFFAF5]`}
            role="navigation"
        >
            <div className="mobile-subnav-header flex items-center w-full sticky top-0 bg-[#FFFAF5]">
                <div
                    className="subnav-nav-back cursor-pointer p-3 relative left-2 z-10 w-[32px]"
                    onClick={handleToggleSubnav}
                    data-nav="nursery"
                >
                    <AngleLeft width="w-[12px]" />
                </div>
                <h2 className="text-center text-2xl flex-1 relative -left-[24px]">
                    Nursery + Gear
                </h2>
                <div
                    onClick={handleCloseMenu}
                    className="mobile-menu-close flex absolute items-center justify-end text-center right-4 top-3"
                >
                    <span className="close-x menu-close relative top-[5px] !font-bold">
                        <CloseX width="w-[16px]" />
                    </span>
                </div>
            </div>
            <ul className="nostyle">
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.nursery}>
                        Shop All Nursery
                    </a>
                </li>
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.cribs_bassinets}>
                        Cribs + Bassinets
                    </a>
                </li>
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.bedding}>
                        Mattresses + Bedding
                    </a>
                </li>
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.changing_pads}>
                        Changing Pads
                    </a>
                </li>
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.baby_blankets}>
                        Blankets
                    </a>
                </li>
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.sleep_bags}>
                        Sleep Bags + Swaddles
                    </a>
                </li>
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.towels}>
                        Towels + Washcloths
                    </a>
                </li>
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.baby_burp_cloths}>
                        Burp Cloths + Bibs
                    </a>
                </li>
                <li className="pl-6 py-3 border-t border-oat font-normal">
                    <a className="block" href={nav.furniture}>
                        Furniture
                    </a>
                </li>
            </ul>
            <div
                className="subsubnav subnav-nursery-health-safety bg-[#FFFAF5] py-3 border-t border-oat"
                data-subnav="gear"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="gear"
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav && activeSubSubnav.gear === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Gear
                    </span>{' '}
                    <span className="ml-auto mr-5">
                        {activeSubSubnav && activeSubSubnav.gear === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-nursery-furniture-decor ${
                        activeSubSubnav && activeSubSubnav.gear
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="gear"
                >
                    <ul className="nostyle">
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.gear_travel}>
                                Shop All Gear
                            </a>
                        </li>

                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.strollers}>
                                Strollers + Accessories
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.high_chairs}>
                                High Chairs
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.bouncers_loungers}>
                                Bouncers + Loungers
                            </a>
                        </li>

                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.toys}>
                                Toys
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.diaper_bags}>
                                Diaper Bags + Accessories
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.carriers}>
                                Carriers
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a
                                className="block"
                                href={nav.activity_centers_play_gyms}
                            >
                                Play Gyms + Mats
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.sound_machines}>
                                Sound Machines + Monitors
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.bathing}>
                                Bathtubs + Bathing Accessories
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.plates_cups}>
                                Feeding Accessories
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className="subsubnav subnav-nursery-furniture-decor bg-[#FFFAF5] py-3 border-t border-oat"
                data-subnav="nursery_moments"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="nursery_moments"
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.nursery_moments === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Shop By Moment
                    </span>{' '}
                    <span className="ml-auto mr-5">
                        {activeSubSubnav &&
                        activeSubSubnav.nursery_moments === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-nursery-furniture-decor ${
                        activeSubSubnav && activeSubSubnav.nursery_moments
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="nursery_moments"
                >
                    <ul className="nostyle">
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.hospital_bag}>
                                Hospital Bag Checklist
                            </a>
                        </li>

                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a
                                className="block"
                                href={nav.baby_registry_essentials}
                            >
                                Baby Registry Checklist
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavNursery
