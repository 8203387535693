import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'
import AngleLeft from '@components/Icons/AngleLeft.jsx'
import CloseX from '@components/Icons/Close-X.jsx'

import * as nav from '@data/navdata'

const SubnavBaby = ({
    activeSubnav,
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
    handleToggleSubnav,
    handleCloseMenu,
}) => {
    console.log('activeSubSubnav', activeSubSubnav)

    return (
        <nav
            className={`subnav-wrap w-full h-full bg-[#FFFAF5]`}
            role="navigation"
        >
            <div className="mobile-subnav-header flex items-center w-full sticky top-0 bg-[#FFFAF5]">
                <div
                    className="subnav-nav-back cursor-pointer p-3 relative left-2 z-10 w-[32px]"
                    onClick={handleToggleSubnav}
                    data-nav="baby"
                    data-subnav="baby_clothes"
                >
                    <AngleLeft width="w-[12px]" />
                </div>
                <h2 className="text-center text-2xl flex-1 relative -left-[24px]">
                    Baby
                </h2>
                <div
                    onClick={handleCloseMenu}
                    className="mobile-menu-close flex absolute items-center justify-end text-center right-4 top-3"
                >
                    <span className="close-x menu-close relative top-[5px] !font-bold">
                        <CloseX width="w-[16px]" />
                    </span>
                </div>
            </div>
            <div
                className="menu-section-header flex items-center w-full py-4 border-t border-oat"
                data-subnav="baby_clothes"
                onClick={handleToggleSubSubnav}
            >
                <span className="menu-section-text pl-6 text-lg">
                    Shop By Category
                </span>{' '}
                <span className="ml-auto mr-5">
                    {activeSubSubnav &&
                    activeSubSubnav.baby_clothes === true ? (
                        <Minus width="w-[16px]" />
                    ) : (
                        <Plus width="w-[18px]" />
                    )}
                </span>
            </div>
            <div
                className={`subsubnav subsubnav-baby-clothes bg-[#FFFAF5] ${
                    activeSubSubnav && activeSubSubnav.baby_clothes === true
                        ? 'block'
                        : 'hidden'
                }`}
                data-subsubnav="baby_clothes"
            >
                <ul className="nostyle">
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_clothes}>
                            Shop All Baby
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_new_arrivals}>
                            Baby New Arrivals
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_hospital}>
                            Newborn Essentials
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_gifts}>
                            Baby Gifts
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_sets}>
                            Sets + Multipacks
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_rompers}>
                            One-Pieces, Rompers + Bodysuits
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_pajamas}>
                            Pajamas
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_dresses}>
                            Dresses
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_shirts}>
                            Shirts + Tops
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_pants}>
                            Pants + Leggings
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_shorts}>
                            Shorts
                        </a>
                    </li>

                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_sweaters}>
                            Sweaters + Sweatshirts
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_jackets}>
                            Jackets + Outerwear
                        </a>
                    </li>

                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_swimwear}>
                            Swimwear
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_blankets}>
                            Blankets + Burp Cloths
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.sleep_bags}>
                            Sleep Bags + Swaddles
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.nursery_gear}>
                            Nursery + Gear
                        </a>
                    </li>

                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.baby_accessories}>
                            Accessories
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal bg-[#FFFAF5]">
                        <a
                            href={nav.sale_baby}
                            className="text-[#BE202A] block"
                        >
                            Sale
                        </a>
                    </li>
                </ul>
            </div>
            <div
                className="subsubnav subnav-baby-sizes bg-[#FFFAF5] py-3 border-t border-oat"
                data-subnav="baby_sizes"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="baby_sizes"
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.baby_sizes === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Shop By Size
                    </span>{' '}
                    <span className="ml-auto mr-5">
                        {activeSubSubnav &&
                        activeSubSubnav.baby_sizes === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-baby-sizes ${
                        activeSubSubnav && activeSubSubnav.baby_sizes
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="baby_sizes"
                >
                    <ul className="nostyle">
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.preemie}>
                                Preemie
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.newborn}>
                                Newborn
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.zero_three}>
                                0–3M
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.three_six}>
                                3–6M
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.six_nine}>
                                6–9M
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.nine_twelve}>
                                9–12M
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.twelve_eighteen}>
                                12–18M
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.eighteen_twentyfour}>
                                18–24M
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className="subsubnav subnav-baby-gender bg-[#FFFAF5] py-3 border-t border-oat"
                data-subnav="baby_gender"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="baby_gender"
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.baby_gender === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Shop By Gender
                    </span>{' '}
                    <span className="ml-auto mr-5">
                        {activeSubSubnav &&
                        activeSubSubnav.baby_gender === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-baby-gender ${
                        activeSubSubnav && activeSubSubnav.baby_gender
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="baby_gender"
                >
                    <ul className="nostyle">
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.baby_boy}>
                                Boy
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.baby_girl}>
                                Girl
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.baby_unisex}>
                                Unisex
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className="subsubnav subnav-baby-sizes bg-[#FFFAF5] py-3 border-t border-oat"
                data-subnav="baby_collections"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="baby_collections"
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.baby_collections === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Shop By Collection
                    </span>{' '}
                    <span className="ml-auto mr-5">
                        {activeSubSubnav &&
                        activeSubSubnav.baby_collections === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-baby-sizes ${
                        activeSubSubnav && activeSubSubnav.baby_collections
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="baby_collections"
                >
                    <ul className="nostyle">
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.matching_family}>
                                Matching Family
                            </a>
                        </li>

                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.embroidery_shop}>
                                Embroidery Shop
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.holiday}>
                                Holiday
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.baby_home}>
                                Bringing Baby Home
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className="subsubnav subnav-baby-moments bg-[#FFFAF5] py-3 border-t border-oat"
                data-subnav="baby_moments"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="baby_moments"
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.baby_moments === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Collaborations
                    </span>{' '}
                    <span className="ml-auto mr-5">
                        {activeSubSubnav &&
                        activeSubSubnav.baby_moments === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-baby-moments ${
                        activeSubSubnav && activeSubSubnav.baby_moments
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="baby_moments"
                >
                    <ul className="nostyle">
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.collaborations}>
                                Shop All Collaborations
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.disney}>
                                Shop All Disney
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.moana}>
                                Disney's Moana
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.lion_king}>
                                Disney's The Lion King
                            </a>
                        </li>

                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.winnie_the_pooh}>
                                Disney's Winnie The Pooh
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.disney_princesses}>
                                Disney Princesses
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.disney_frozen}>
                                Disney's Frozen
                            </a>
                        </li>

                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.mickey_minnie}>
                                Disney's Mickey + Minnie Groovy
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.disney_cars}>
                                Disney's Cars
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.eric_carle}>
                                Eric Carle
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.paris_hilton}>
                                Paris Hilton
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.barbie}>
                                Barbie
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavBaby
